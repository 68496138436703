import React from 'react'
import img1 from '../assets/about1.jpg'
import img2 from '../assets/about2.jpg'
import img3 from '../assets/about3.jpg'
import car from '../assets/carimg.png'

export default function About() {
  return (
    <div id="main-about">
      <div id="ma-top">
        <h1>About Us</h1>
        <h3>Home/About Us</h3>
        <img src={car} alt="car"/>
      </div>
      <div id="about-text">
        <h2>Our Story</h2>
        <h1>Piezas Auto Spare Parts Trading L.L.C</h1>
        <p>We provide the best auto spare parts in Dubai as we know the quality is very important for when it comes to automobiles. We also export our goods and services to abroad regions. We cover everything from auto parts, automotive electrical parts, auto spare parts, automobile brakes, automobile tires, etc. We make sure that the quality of our products is top notch and that none of our customer get any hiccups during their purchase and recieve the most satisfaction possible.</p>
      </div>
      <div id="banner-img">
        <img src={img1} alt="" />
        <img src={img3} alt="" />
        <img src={img2} alt="" />
      </div>
    </div>
  )
}
