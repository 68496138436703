import React from 'react'
import car from '../assets/carimg.png'
import icon1 from '../assets/phone.webp'
import icon2 from '../assets/messages.webp'
import icon3 from '../assets/homeicon.webp'
import cust from '../assets/custsupport.png'

export default function Contact() {
  return (
    <div id="main-about">
      <div id="ma-top">
        <h1>Contact Us</h1>
        <h3>Home/Contact Us</h3>
        <img src={car} alt="car"/>
      </div>
      <div id="contact-details">
        <div className="cd-item">
          <img src={icon1} alt="" className="cd-img" />
          <div className="cd-text">
            <h1>Phone</h1>
            <p>+971 4 239 4624</p>
          </div>
        </div>
        <div className="cd-item">
          <img src={icon2} alt="" className="cd-img" />
          <div className="cd-text">
            <h1>Email/Web</h1>
            <p>piezas207@gmail.com</p>
          </div>
        </div>
        <div className="cd-item">
          <img src={icon3} alt="" className="cd-img" />
          <div className="cd-text">
            <h1>Address</h1>
            <p>Office 207, Salam Center, Baniyas Square, Dubai - UAE</p>
          </div>
        </div>
      </div>
      <div id="send-messages">
        <img src={cust} alt=""/>
        <form>
          <h3>Send Us a Message</h3>
          <div id="inpu-fields-form">
            <input type="text" placeholder='name'/>
            <input type="email" placeholder='email'/>
          </div>
          <textarea placeholder='Message'/>
          <button>Submit</button>
        </form>
      </div>
  </div>
  )
}
