import React from 'react'
import Slider from '../components/Slider';
import '../styles/extra.css';
import headphones from '../assets/headset.png';
import shipping from '../assets/shipping.png';
import shield from '../assets/shield.png';
import Featurettes from '../components/Featurettes';
const Home = () => {
  return (
    <>
      <Slider />
      <div class="container marketing">


        <div class="row">
          <div class="col-lg-4">
            <img src={shipping} class="bd-placeholder-img" focusable="false" alt='shipping' />
            <title>Placeholder</title>
            <h2>Free Shipping</h2>
            <p>Provide free home delivery for all product over $500</p>
            <p><button class="btn btn-secondary" >View details &raquo;</button></p>
          </div>
          <div class="col-lg-4">
            <img src={headphones} class="bd-placeholder-img rounded-circle" focusable="false" alt='headset' />
            <title>Placeholder</title>
            <h2>Online Support</h2>
            <p>To satisfy our customer we give support online &nbsp;</p>
            <p><button class="btn btn-secondary" >View details &raquo;</button></p>
          </div>
          <div class="col-lg-4">
            <img src={shield} class="bd-placeholder-img rounded-circle" focusable="false" alt='shield'/>
            <title>Placeholder</title>
            <h2>Security</h2>
            <p>We ensure our product quality at all times &emsp;&emsp;&emsp;   </p>
            <p><button class="btn btn-secondary" href="#">View details &raquo;</button></p>
          </div>
        </div>
      </div>
      <Featurettes />
    </>


  )
}
export default Home;