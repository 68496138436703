import React from 'react';
import '../styles/featurette.css';
import parts from '../assets/photo3.png';
import car from '../assets/car.jpg';

function Featurettes({ title, tagline, content }) {
  return (
    <>
      <hr className="featurette-divider" />
      <div className='featurette-container'>


        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading"> <span className="text-muted">Great quality parts at affordable prices.</span></h2>
            <p className="lead">We believe in giving our customers what they want at the right price.</p>
          </div>
          <div className="col-md-5 img-container">
            <img src={parts} className="bd-placeholder-img parts-img" focusable="false" />

          </div>
        </div>
      </div>
      <hr className="featurette-divider" />

      <div className='featurette-container'>
        <div className="row featurette">
          <div className="col-md-6 order-md-1 img-container">
            <img src={car} className="bd-placeholder-img car-img" focusable="false" />

          </div>
          <div className="col-md-5 order-md-2">
            <h2 className="featurette-heading pr-0">What are you waiting for?</h2>
            <p className="lead pr-0">Give your car the makeover you have always wanted.</p>
          </div>

        </div>
      </div>

    </>
  )
}

export default Featurettes